<template>

    <div :class="['modal_dialog', sizes, transparentModalDialog ? '!h-auto backDialog' : '' ]">
        <slot></slot>
    </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
    size: {
        type: String,
        default: "default"
    },
    transparentModalDialog:{
        type: Boolean,
        default:false,
    },
});

const sizes = computed(() => {
    if (props.size === "small") {
        return "modal_small";
    } else if (props.size === "medium") {
        return "modal_medium";
    } 
    else if (props.size === "normal") {
        return "modal_normal";
    } else if (props.size === "xsmall") {
        return "modal_xsmall";
    } else if (props.size === "large") {
        return "modal_large";
    } else {
        return "modal_default";
    }
});
</script>

<style scoped>
.modal_dialog {
    @apply sm:h-[calc(100vh-3rem)] z-50 flex items-center;
}

.modal_default {
    @apply w-screen sm:max-w-[77.5rem] m-auto sm:w-[calc(100%-9.25%)];
}
.modal_large {
    @apply w-screen sm:max-w-[69.2rem] m-auto sm:w-[calc(100%-9.25%)];
}
.modal_medium {
    @apply sm:w-[calc(100%-9.25%)] sm:max-w-[40rem] m-auto xs:w-full;
}

.modal_small {
    @apply w-[calc(100%-33px)] xs:w-full xs:block sm:max-w-[25rem] m-auto sm:min-h-[21.125rem];
}
.modal_normal {
    @apply w-screen sm:max-w-[40rem] sm:w-[calc(100%-9.25%)] m-auto xs:w-full;
}
.modal_xsmall {
    @apply  md:h-[calc(100vh-37rem)] sm:w-[calc(100%-9.25%)] w-[360px] sm:max-w-[40rem] m-auto;                                          
}
.backDialog{
    width: max-content !important;
}


</style>
